import LogPrinter from "../LogPrinter";
const log = LogPrinter("userLogReducer");

const initialState = {
  userLog: null,
  status: ""
}

const userLog = (state = initialState, action) => {
  if (action.category !== "userLog") 
    return state;

  log("Request:", action.type);

  var newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case 'UPDATE_STATUS':
      newState.status = action.status;
      if (action.status === "isFetching") {
        log("Fetching userLog list");
        newState.userLog = null;
      }
      return newState;
    case 'UPDATE_LOGS':
      log("Updated user logs");
      newState.status = "done";
      newState.userLog = action.userLog;
      return newState;
    default:
      return state
  }
}

export default userLog