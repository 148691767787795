const LOG_CONTROL_MAP = {

  // components
  FullView: false,
  RealtimeBlock: false,
  DateNavigator: false,
  PasswordChanger: false,

  // containers
  AnalyticsContainer: false,
  FullContainer: false,
  HeaderContainer: false,
  HomeContainer: false,
  LoginContainer: false,
  SystemsContainer: false,

  // reducers
  alertHistoryReducer: false,
  alertsReducer: false,
  analyticsReducer: false,
  indexReducer: false,
  interpolationReducer: false,
  newsReducer: false,
  reportsReducer: false,
  settingsReducer: false,
  systemsReducer: false,
  subscriptionsReducer: false,
  userReducer: false,
  userLogReducer: false,
  weatherReducer: false,
  tourReducer: false,
  loginReducer: false,
  homeLayoutReducer: false,

  //saga
  Saga: false,

  // utils
  dateUtils: false,
  chartUtils: false,
  session: false,
  AnalyticsChart: false,
  RealtimeChart: false,
  SystemsChart: false,
  StockChart: false,
  DMYSelector: false,
  SystemsDateNavigator: false,

  //views
  AnalyticsView: false,
  LoginView: false,
  HomeView: false,
  SystemsView: false,
}

function LogPrinter(fileName) {
  return function log(...args) {
    if (LOG_CONTROL_MAP[fileName]) console.log(fileName + ":", ...args);
    // console.log(fileName + ":", ...args);
  }
}

export default LogPrinter;