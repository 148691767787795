import _ from "underscore";
import LogPrinter from "../LogPrinter";
const log = LogPrinter("newsReducer");

const initialState = {
  retrievedData: null,
  status: ""
}

const news = (state = initialState, action) => {
  var newState = _.extend({}, state);

  switch (action.type) {
    case 'REQUEST_NEWS':
      log("Fetching news data");
      newState.status = "isFetching";
      newState.retrievedData = null;
      return newState;
    case 'UPDATE_NEWS':
      log("Filling up news data", action.news);
      newState.status = action.isSuccess ? "done" : "failed";
      newState.retrievedData = action.news;
      return newState;
    default:
      return state
  }
}

export default news