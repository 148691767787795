const items = [
  { i: "overview", w: 12, h: 1, x: 0, y: 0 },
  { i: "graph-current", w: 12, h: 3, x: 0, y: 0, minW: 3, minH: 2},
]

const mobileItems = [
  { i: "overview", w: 12, h: 1, x: 0, y: 0 },
  { i: "graph-current", w: 6, h: 3, x: 0, y: 0, minW: 3, minH: 2},
]

export const defaultLayout = {
  lg: items,
  md: items,
  sm: mobileItems,
  xs: mobileItems,
  xxs: mobileItems,
};

const zeroExportItems = [
  { i: "overview", w: 12, h: 1, x: 0, y: 0 },
  { i: "graph-current", w: 6, h: 3, x: 0, y: 0, minW: 3, minH: 2},
  { i: "generation", w: 6, h: 3, x: 6, y: 0, minW: 3, minH: 2},
]

const zeroExportMobileItems = [
  { i: "overview", w: 12, h: 1, x: 0, y: 0 },
  { i: "graph-current", w: 6, h: 3, x: 0, y: 0, minW: 3, minH: 2},
  { i: "generation", w: 6, h: 3, x: 0, y: 0, minW: 3, minH: 2},
]

export const zeroExportLayout = {
  lg: zeroExportItems,
  md: zeroExportItems,
  sm: zeroExportMobileItems,
  xs: zeroExportMobileItems,
  xxs: zeroExportMobileItems,
};