const notifications = (state = [], action) => {
  switch (action.type) {
    case "INIT": 
      console.log("Initiating notifications. Filling up with notifs");
      return action.history;
    case 'ADD_NOTIF':
      console.log("Adding new notif");
      return [
        ...state,
        {
          time: action.time,
          content: action.content,
          state: action.state
        }
      ];
    default:
      return state
  }
}

export default notifications