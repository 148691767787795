import LogPrinter from "../LogPrinter";
const log = LogPrinter("tourReducer");

const defaultState = {
    isVisible: false,
    isPaused: false,
    currentView: "help",
    currentStep: 0,
};

const tourReducer = (state = defaultState, action = {}) => {
	if (action.category !== "tour") 
		return state;

	log("Request:", action.type, action);
	var newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case "SET_VISIBILITY":
            newState.isVisible = action.isVisible;
           break;
        case "SET_PAUSED_STATE":
            newState.isPaused = action.isPaused;
           break;
        case "SET_CURRENT_STEP":
            newState.currentStep = action.currentStep;
           break;
        case "SET_CURRENT_VIEW":
            newState.currentView = action.currentView;
           break;
        default:
            return state;
    }

    return newState;
};

export default tourReducer;
