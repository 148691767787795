import { combineReducers } from 'redux'
import settings from './settings'
import weather from './weather'
import news from './news'
import analytics from './analytics'
import systems from './systems'
import notifications from './notifications'
import subscriptions from './subscriptions'
import alerts from './alerts'
import alertHistory from './alertHistory'
import reports from './reports'
import userLog from './userLog'
import interpolation from './interpolation'
import bugReport from './bugReport'
import passwordReset from './passwordReset'
import tourReducer from './tourReducer'
import login from './login'
import homeLayout from './homeLayout'

import LogPrinter from "../LogPrinter";
const log = LogPrinter("indexReducer");

const reducers = combineReducers({
  settings,
  news,
  weather,
  analytics,
  systems,
  notifications,
  subscriptions,
  alerts,
  alertHistory,
  reports,
  userLog,
  interpolation,
  bugReport,
  passwordReset,
  tourReducer,
  login,
  homeLayout
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    log("User logged out. Clearing reducer state.");
    state = undefined
  }

  return reducers(state, action)
}

export default rootReducer