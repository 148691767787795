import { createStore } from 'redux';
import appReducer from '../reducers';

export const configureStore = () => {
  const store = createStore(
    appReducer,
    /*window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()*/
  );

  return store;
};