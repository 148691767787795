import moment from "moment";
import LogPrinter from "../LogPrinter";
const log = LogPrinter("dateUtils");

export function convertToDate(timestamp) {
  return moment(timestamp).toDate();
}

export function minutesApart(start, end) {
  var diff = moment(end).diff(moment(start));
  return moment.duration(diff).asMinutes();
}

export function formatDate(timestamp) {
  return isNaN(timestamp) ? "-" : moment(timestamp).format("MMM DD, YYYY - h:mm A");
}

export function formatUTCDate(timestamp) {
  return isNaN(timestamp) ? "-" : moment.utc(timestamp).format("MMM DD, YYYY - h:mm A");
}

export function formatUTCTimeOnly(timestamp) {
  return isNaN(timestamp) ? "-" : moment.utc(timestamp).format("h:mm A");
}

export function formatTimeOnly(timestamp) {
  return isNaN(timestamp) ? "-" : moment(timestamp).format("hh:mm A");
}

export function formatDateOnly(timestamp) {
  return moment(timestamp).format("MMM DD, YYYY");
}

export function formatDateWithWeekDay(timestamp) {
  return moment(timestamp).format("MMMM DD, YYYY (ddd)");
}

export function formatStartDate(timestamp) {
  return moment(timestamp).format("MMM DD");
}

export function formatEndDate(timestamp) {
  return moment(timestamp).format("DD, YYYY");
}

function isSameMonth(start, end) {
  return moment(start).get("month") === moment(end).get("month");
}

export function formatDateRange(start, end) {
  if (formatDateOnly(start) === formatDateOnly(end)) {
    return formatDateOnly(start);
  } else if (isSameMonth(start, end)) {
    return formatStartDate(start) + " - " + formatEndDate(end);
  } else {
    return formatStartDate(start) + " - " + formatDateOnly(end);
  }
}

export function formatUTCDateRange(start, end) {

  function formatUTCDateOnly(timestamp) {
    return moment.utc(timestamp).format("MMM DD, YYYY");
  }

  function isSameUTCMonth(start, end) {
    return moment.utc(start).get("month") === moment.utc(end).get("month");
  }

  function formatUTCStartDate(timestamp) {
    return moment.utc(timestamp).format("MMM DD");
  }

  function formatUTCEndDate(timestamp) {
    return moment.utc(timestamp).format("DD, YYYY");
  }


  if (formatUTCDateOnly(start) === formatUTCDateOnly(end)) {
    return formatUTCDateOnly(start);
  } else if (isSameUTCMonth(start, end)) {
    return formatUTCStartDate(start) + " - " + formatUTCEndDate(end);
  } else {
    return formatUTCStartDate(start) + " - " + formatUTCDateOnly(end);
  }
}

export function dateToUtcSqlTime(time) {
  return moment.utc(time).format('YYYY-MM-DD HH:mm:ss');
}

export function dateToSqlDateTime(time) {
  return moment(time).format('YYYY-MM-DD HH:mm:ss');
}

export function dateToSqlDate(time) {
  return moment(time).format('YYYY-MM-DD');
}

export function extendToMonthCoverage(start, end) {
  start.setDate(1);
  start.setHours(0);
  start.setMinutes(0);
  start.setSeconds(0);
  end.setTime(moment(end).add(1, "months").toDate());
  end.setDate(0);
  end.setHours(23);
  end.setMinutes(59);
  end.setSeconds(59);
  log("Extended dates:", start.toLocaleString(), end.toLocaleString());
}

export function generateBillingDates(refDate, billStart, billEnd, billingMonths) {
  var start, end;

  if (billingMonths === 0 || billingMonths === "0") { // same month
    start = moment.utc(refDate).set("date", billStart);

    var endOfMonth = moment.utc(refDate).endOf("month").get("date");
    end = billEnd > endOfMonth ?
      moment.utc(refDate).endOf("month") :
      moment.utc(refDate).set("date", billEnd);
  } else {
    if (refDate.getDate() <= billEnd) {
      start = moment.utc(refDate).set("date", billStart).subtract(1, "months");
      end = moment.utc(refDate).set("date", billEnd);
    } else {
      start = moment.utc(refDate).set("date", billStart);
      end = moment.utc(refDate).set("date", billEnd).add(1, "months");
    }
  }

  return {
    startingDate: start.startOf("day").toDate(),
    endingDate: end.endOf("day").toDate()
  }
}

export function generateStartEndDates(time, referenceDate, billStart, billEnd, billingMonths) {
  var day = referenceDate.getDay();
  var start = moment(referenceDate.getTime()).toDate();
  var end = moment(referenceDate.getTime()).toDate();

  switch (time) {
    case "latest":
      end.setTime(moment.utc())
      start.setTime(moment(end.getTime()).subtract(30, "minutes").toDate());
      end.setTime(moment(referenceDate.getTime()).toDate());
    break;
    case "week":
      start.setDate(referenceDate.getDate() - (day));
      end.setDate(referenceDate.getDate() + (6 - day));
    break;
    case "month":
      // start.setDate(1);
      start = moment(referenceDate.getTime()).startOf("month").toDate();
      // end.setTime(moment(start).endOf('month').toDate());
      end = moment(referenceDate.getTime()).endOf("month").toDate();
    break;
    case "year":
      start = moment(referenceDate.getTime()).startOf("year").toDate();
      end = moment(referenceDate.getTime()).endOf("year").toDate();
    break;
    case "billing":
      if (billingMonths === 0 || billingMonths === "0") { // same month
        /*start.setDate(billStart);

        if (billEnd > moment(referenceDate).endOf("month").get("date")) {
          end.setDate(moment(referenceDate).endOf("month").get("date"));
        } else {
          end.setDate(billEnd);
        }*/
        start = moment(referenceDate.getTime()).startOf("month").toDate();
        end = moment(referenceDate.getTime()).endOf("month").toDate();
      } else {
        if (referenceDate.getDate() <= billEnd) {
          start.setTime(moment(start).subtract(1, "months").toDate());
          start.setDate(billStart);
          end.setDate(billEnd);
        } else {
          start.setDate(billStart);
          end.setTime(moment(end).add(1, "months").toDate());
          end.setDate(billEnd);
        }
      }
    break;
    default:break;
  }


  if (time !== "latest" && time !== "month") {
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);

    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
  }
  log("GENERATED TIMES:", start, end);

  return {
  	startingDate: start,
  	endingDate: end
  }
}

export function timeDifference(current, previous) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed/1000) + 's ago';
  }

  else if (elapsed < msPerHour) {
    return Math.round(elapsed/msPerMinute) + 'min ago';
  }

  else if (elapsed < msPerDay ) {
    return Math.round(elapsed/msPerHour ) + 'h ago';
  }

  else if (elapsed < msPerMonth) {
    return Math.round(elapsed/msPerDay) + 'd ago';
  }

  else if (elapsed < msPerYear) {
    var monthsAgo = Math.round(elapsed/msPerMonth);
    return monthsAgo + (monthsAgo > 1 ? ' months ago' : " month ago");
  }

  else {
    var yearsAgo = Math.round(elapsed/msPerYear);
    return yearsAgo + (yearsAgo > 1 ? ' years ago' : " years ago");
  }
}

export function enumerateDaysBetweenDates(startDate, endDate) {
  let start = moment(startDate);
  let end = moment(endDate);
  var now = start.clone(), dates = [];

  while (now.isSameOrBefore(end)) {
      dates.push(now.valueOf());
      now.add(1, 'days');
  }
  return dates;
};