import _ from "underscore";
import LogPrinter from "../LogPrinter";
const log = LogPrinter("reportsReducer");

const initialState = {
  reportsUrl: null,
  reportsList: null,
  status: "noData",
  requestStatus: "",
  errorText: "",
}

const reports = (state = initialState, action) => {
  if (action.category !== "reports") 
    return state;

  log("Request:", action.type, action);
  var newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case 'REQUEST_REPORTS_LIST':
      newState.status = "isFetching";
      newState.reportsUrl = null;
      newState.reportsList = null;
      return newState;
    case 'UPDATE_REPORTS_LIST':
      log("Updating reports list", action.reportsList);
      newState.status = action.isSuccess ? "updated" : "failed";
      newState.reportsUrl = action.reportsUrl;
      newState.reportsList = _.map(action.reportsList, (report, i) => {
        return {
          name: report.name,
          status: report.status,
          engineering_status: report.engineering_status || "FOR APPROVAL",
          accounting_status: report.accounting_status  || "FOR APPROVAL",
          id: report.Id,
          siteId: report.siteId
        }
      });
      return newState;
    case "UPDATE_REPORT":
      newState.reportsList[action.index] = _.extend({}, newState.reportsList[action.index], action.report);
      return newState;
    case 'SET_REPORT_REQ_STATUS':
      newState.requestStatus = action.requestStatus;
      newState.errorText = action.errorText;
      return newState;
    default:
      return state
  }
}

export default reports