import LogPrinter from "../LogPrinter";
const log = LogPrinter("interpolationReducer");

const initialState = {
  isInterpolated: true
}

const interpolation = (state = initialState, action) => {
  if (action.category !== "interpolation") 
    return state;

  var newState = JSON.parse(JSON.stringify(state));
  log("Request:", action.type, action);

  switch (action.type) {
    case "UPDATE_STATE":
      console.log("Updating interpolated state:", action.isInterpolated);
      newState.isInterpolated = action.isInterpolated;
    break;
    default:
      return state
  }

  return newState;
}

export default interpolation