import LogPrinter from "../LogPrinter";
const log = LogPrinter("alertsReducer");

const initialState = {
  activeAlerts: [],
  activeInterpolated: [],
}

const alerts = (state = initialState, action) => {
  if (action.category !== "alerts") 
    return state;

  var newState = JSON.parse(JSON.stringify(state));
  log("Request:", action.type, action);

  switch (action.type) {
    case "INIT": 
      return initialState;
    case "UPDATE_ALERTS":
      log("Updating alerts:", action.alerts);
      newState.activeAlerts = action.alerts.filter((alert) => { 
        return (Object.keys(alert).length > 0) 
      });
      newState.activeInterpolated = action.alertsInterpolated.filter((alert) => { 
        return (Object.keys(alert).length > 0) 
      });
    break;
    default:
      return state
  }

  return newState;
}

export default alerts