import _ from "underscore";
import * as widgetActions from "../actions/widgetActions";
import * as LAYOUT from "../constants/layout";

import LogPrinter from "../LogPrinter";
const log = LogPrinter("homeLayoutReducer");

const initialState = {
  current: LAYOUT.defaultLayout,
  saved: LAYOUT.defaultLayout
}

const homeLayout = (state = initialState, action) => {
  if (action.category !== "homeLayout")
    return state;

  log("Request:", action.type);

  var newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case widgetActions.UPDATE_LAYOUT:
      if (action.editMode === true) {
        newState.current = action.layout;
      } else {
        newState.saved = state.current;
      }
    break;
    case widgetActions.ADD_WIDGET:
      console.log("Widget:", action.widgetInfo, action.breakpoint);
      newState.current[action.breakpoint].unshift(action.widgetInfo);
    break;
    case widgetActions.REMOVE_WIDGET:
      var indexToRemove = _.findIndex(state.current.lg, (item) => {return item.i === action.widgetId;});
      newState.current[action.breakpoint].splice(indexToRemove, 1);
    break;
    default:
      return state
  }
  return newState;
}

export default homeLayout