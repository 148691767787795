export const UPDATE_LAYOUT = 'UPDATE_LAYOUT'
export const ADD_WIDGET = 'ADD_WIDGET'
export const REMOVE_WIDGET = 'REMOVE_WIDGET'
export const UPDATE_WIDGET = "UPDATE_WIDGET"

export function updateLayout(layout, editMode) {
  return { category: "homeLayout", type: UPDATE_LAYOUT, layout, editMode }
}

export function addWidget(widgetInfo, breakpoint) {
  return { category: "homeLayout", type: ADD_WIDGET, widgetInfo, breakpoint }
}

export function removeWidget(widgetId, breakpoint) {
  return { category: "homeLayout",  type: REMOVE_WIDGET, widgetId, breakpoint }
}

export function updateWidget(widgetInfo, breakpoint) {
  return { category: "homeLayout",  type: UPDATE_WIDGET, widgetInfo, breakpoint }
}