import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from "./stores/Store"
import Loadable from "react-loadable";

const MyLoadingComponent = ({isLoading, error}) => {
  // Handle the loading state
  if (isLoading) {
    return <div className="animated fadeIn se-pre-con">
      <h5 className="text-white animated fadeIn infinite">
        Page is loading, please wait...
      </h5>
    </div>;
  }
  // Handle the error state
  else if (error) {
    return <div className="text-white">Sorry, there was a problem loading the page: {error}</div>;
  }
  else {
    return null;
  }
};

const AsyncFull = Loadable({
  loader: () => import("./containers/FullContainer"),
  loading: MyLoadingComponent
});

const AsyncLogin = Loadable({
  loader: () => import("./containers/LoginContainer"),
  loading: MyLoadingComponent
});

const AsyncHelp = Loadable({
  loader: () => import("./containers/BugReportContainer"),
  loading: MyLoadingComponent
});

var store = configureStore();

class EnvisionApp extends Component {
  render() {
    return <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={AsyncLogin} />
          <Route exact path="/report" name="Support Page" component={AsyncHelp} />
          <Route path="/redirect/:view" name="Login Page" component={AsyncLogin} />
          <Route path="/" name="Home" component={AsyncFull} />
        </Switch>
      </BrowserRouter>
    </Provider>;
  }
}

export default EnvisionApp;