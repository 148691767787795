import LogPrinter from "../LogPrinter";
const log = LogPrinter("alertHistoryReducer");

const initialState = {
  alertsList: null,
  alertsInterpolated: null,
  status: ""
}

const alerts = (state = initialState, action) => {
  if (action.category !== "alertHistory") 
    return state;

  var newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case 'UPDATE_STATUS':
      newState.status = action.status;
      if (action.status === "isFetching") {
        log("Fetching alerts list");
        newState.alertsList = null;
      }
      return newState;
    case 'UPDATE_ALERT_HISTORY':
      log("Updating alert history");
      newState.status = "success";
      newState.alertsList = action.alerts;
      newState.alertsInterpolated = action.alertsInterpolated;
      return newState;
    default:
      return state
  }
}

export default alerts