import _ from "underscore";
import LogPrinter from "../LogPrinter";
const log = LogPrinter("settingsReducer");

const DEFAULT_SYSTEM = "METERING";

const initialState = {
  isInit: false,

  // PROFILE DETAILS
  idUser: "",
  username: "",
  password: "",
  theme: "orange",
  img: __dirname + "img/default.png" ,

  // USER PERMISSION
  userType: "",
  permissions: "",
  subscriptions: [],

  // ACCOUNT SETTINGS
  kit: "standard",
  isMasterAccount: 1,
  userList: [],
  acct_admin: false,

  // MONITORING DETAILS
  contractedCapacity: -1,
  contCapAlias: "CONTRACTED CAPACITY",
  capacityChanged: undefined,
  wesmNode: "",
  powerSources: [],

  // NOTIFS
  alert_flag: "",
  min_interval: "",
  email_dest: "",

  // WEATHER
  weatherLoc: "",
  lat: "",
  lon: "",
  weatherCoordinates: "x,y",

  // BILLING PERIOD SETTINGS
  billingMonths: 1,
  billStart: 1,
  billEnd: 1,
  billSummaryUrl: "",

  // PROJECT DETAILS:
  projectId: "",
  projectName: "",
  projectList: [],

  // SITE DETAILS
  siteId: "",
  siteName: "",
  isMainSite: false,
  system: DEFAULT_SYSTEM,


  // NO IDEA:
  notifText: "",
  notifType: "",
  newUserName: "",
  newPassword: "",
  newUserType: "",
  otherUserInfo: "{}",
  getUserListStatus: "",
  updateStatus: "",
  isPreview: false,
  previewTheme: "",
  user: {
    needRefresh: false
  },

  // genCharges: 0,
  // forexRates: 0,
  // duFees: 0,
  // transmissionCost: 0,
  // vat: 0,
}

function adjustData(data) {
  let updatedData = {
    siteId: data.siteId,
    token: data.token,
    theme: data.theme,
    alert_flag: data.alert_flag,
    min_interval: data.min_interval,
    kit: data.kit,
    projectList: data.sites,
    billingMonths: data.billingMonths,
    billStart: data.billStart,
    billEnd: data.billEnd,
    img: data.profile_picture !== "None" ? data.profile_picture : __dirname + "img/default.png",
  }

  if (data.sites) {
    if (Object.keys(data.sites).length) {
      let siteData = _.findWhere(data.sites, {siteid: +data.siteId});

      updatedData.siteName = siteData.name;
      updatedData.isMainSite = siteData.ismain === "true";
      updatedData.weatherLoc = siteData.weather_loc;
      updatedData.lat = siteData.lat;
      updatedData.lon = siteData.lon;
      updatedData.billingMonths = +siteData.billing_months;
      updatedData.billStart = siteData.bill_start;
      updatedData.billEnd = siteData.bill_end;
      updatedData.wesmNode = siteData.wesm_node;
      updatedData.contractedCapacity = siteData.contract_capacity;
      updatedData.contCapAlias = (siteData.contract_alias !== "None" && siteData.contract_alias !== "") ? siteData.contract_alias : "Target Demand";
      updatedData.system = siteData.system_type || DEFAULT_SYSTEM;
    } else {
      updatedData.siteName = "";
      updatedData.isMainSite = false;
      updatedData.weatherLoc = "Manila, Metro Manila";
      updatedData.lat = 14.59787245;
      updatedData.lon = 120.983658643719;
      updatedData.billingMonths = 0;
      updatedData.billStart = 0;
      updatedData.billEnd = 30;
      updatedData.wesmNode = "Balintawak";
      updatedData.contractedCapacity = 3000;
      updatedData.contCapAlias = "Target Demand";
      updatedData.system = DEFAULT_SYSTEM;
    }
  }

  if (data.powerSources2) {
    updatedData.powerSources = [];
    _.each(data.powerSources2, (source) => {
      updatedData.powerSources.push(source);
    });
  }
  if (data.powerSources) {
    updatedData.powerSources = [];
    _.each(data.powerSources, (source) => {
      updatedData.powerSources.push(source);
    });
  }

  Object.keys(updatedData).forEach((key) => {
    if (updatedData[key] === undefined) {
      delete updatedData[key];
    }
  });

  console.log("SettingsReducer: Updated data:", updatedData);

  return updatedData;
}

function updateSavedUserData(newState) {
  let userData = JSON.parse(sessionStorage.getItem("userData"));

  userData.username = newState.username;
  userData.password = newState.password;
  userData.theme = newState.theme;
  userData.profile_picture = newState.img;
  userData.subscriptions = newState.subscriptions;
  userData.kit = newState.kit;
  userData.masteraccount = newState.isMasterAccount;
  userData.contract_capacity = newState.contractedCapacity;
  userData.contCapAlias = newState.contCapAlias;
  userData.capacity_changed = newState.capacityChanged / 1000;
  userData.wesm_node = newState.wesmNode;
  userData.powerSources2 = newState.powerSources;
  userData.alert_flag = newState.alert_flag;
  userData.min_interval = newState.min_interval;
  userData.email_dest = newState.email_dest;
  userData.weather_loc = newState.weatherLoc;
  userData.lat = newState.lat;
  userData.lon = newState.lon;
  userData.billing_months = newState.billingMonths;
  userData.bill_start = newState.billStart;
  userData.bill_end = newState.billEnd;
  userData.ProjectId = newState.projectId;
  userData.sites = newState.projectList;
  // userData.system = newState.system;
  userData.SiteId = newState.siteId;

  sessionStorage.setItem("userData", JSON.stringify(userData));
}

const settings = (state = initialState, action) => {
  if (action.category !== "settings")
    return state;

  var newState = JSON.parse(JSON.stringify(state));
  var userData;
  log("Request:", action.type, action);

  switch (action.type) {
    case "INIT_USER":
      if (state.isInit === true) {
        log("Init user already done.")
        return state;
      }
      userData = JSON.parse(action.user);

      log("Init user with data:", userData);
      newState.isInit = true;

      // PROFILE DETAILS
      newState.idUser = userData.idUser;
      newState.username = userData.username;
      newState.password = userData.password;
      newState.theme = userData.theme !== "" ? userData.theme : "orange";
      newState.img = userData.profile_picture !== "None" ? userData.profile_picture : __dirname + "img/default.png";

      // USER PERMISSION
      newState.userType = userData.user_type;
      newState.permissions = userData.permissions;
      newState.subscriptions = userData.subscriptions;

      // ACCOUNT SETTINGS
      newState.kit = userData.kit;
      newState.isMasterAccount = userData.masteraccount;
      newState.userList = userData.user_list;
      newState.acct_admin = userData.acct_admin !== "No";

      // MONITORING DETAILS
      newState.contractedCapacity = userData.contract_capacity !== "None" ? Number(userData.contract_capacity) : 10;
      newState.contCapAlias = (userData.contCapAlias !== "None" && userData.contCapAlias !== "") ? userData.contCapAlias : "Maximum Power Usage (Contracted Capacity)";
      newState.capacityChanged = Number(userData.capacity_changed) * 1000;
      newState.wesmNode = userData.wesm_node !== "None" ? userData.wesm_node : "Balintawak";
      newState.powerSources =  userData.powerSources2;

      // EMAIL NOTIFS
      newState.alert_flag = userData.alert_flag;
      newState.min_interval = userData.min_interval;
      newState.email_dest = userData.email_dest;

      // WEATHER SETTINGS
      newState.weatherLoc = Object.keys(newState.projectList).length ? _.findWhere(newState.projectList, {siteid: +newState.siteId}).weather_loc : "Manila, Metro Manila";
      newState.lat = userData.lat !== "None" ? userData.lat : 14.59787245;
      newState.lon = userData.lon !== "None" ? userData.lon : 120.983658643719;

      // BILLING PERIOD SETTINGS
      newState.billingMonths = userData.billing_months !== "None" ? +userData.billing_months : 1;
      newState.billStart = userData.bill_start;
      newState.billEnd = userData.bill_end;

      // PROJECT DETAILS
      newState.projectId = userData.ProjectId;
      newState.projectName = userData.projectname;
      newState.projectList = userData.sites;

      // SITE DETAILS
      newState.siteId = userData.SiteId;
      let siteInfo = Object.keys(newState.projectList).length ? _.findWhere(newState.projectList, {siteid: +newState.siteId}) : null;

      if (siteInfo) {
        newState.siteName = siteInfo.name;
        newState.isMainSite = siteInfo.ismain === "true";
        newState.system = siteInfo.system_type;
      } else {
        newState.siteName = "";
        newState.isMainSite = false;
        newState.system = DEFAULT_SYSTEM;
      }

      /*if (newState.siteName === "FPSC" && newState.userType === "admin") {
        newState.genCharges = userData.gen_charge;
        newState.forexRates = userData.forex_rates;
        newState.duFees = userData.du_fees;
        newState.transmissionCost = userData.transmission_cost;
        newState.vat = userData.vat;
      }*/
    break;

    case "REFRESH_USER_LIST":
      log("Setting user to need refresh");
      newState.user.needRefresh = true;
    break;

    case "RESET":
      return initialState;

    case "SET_BILL_SUMMARY_URL":
      newState.billSummaryUrl = action.url;
    break;

    case "SET_NOTIF_TEXT":
      newState.notifText = action.notifText;
      newState.notifType = action.notifType;
    break;

    case "SET_OTHER_USER":
      newState.otherUserInfo = JSON.stringify(action.user);
      log("Setting other user", newState.otherUserInfo);
    break;

    case "SET_PROJECT_LIST":
      newState.projectList = action.projectList;
    break;

    case "SET_USER_LIST_GET_STATUS":
      newState.getUserListStatus = action.status;
    break;

    case "SET_SITE":
      newState.siteId = action.siteId;
      newState.siteName = action.siteName;

      newState.isMainSite = false;
      if (newState.projectList && Object.keys(newState.projectList).length) {
        let siteData = _.findWhere(newState.projectList, {siteid: +action.siteId});
        if (siteData) {
          newState.isMainSite = siteData.ismain === "true";
        }
      }
    break;

    case "SET_THEME_PREVIEW":
      newState.isPreview = action.isPreview;
      newState.previewTheme = action.previewTheme;
    break;

    case 'UPDATE_IMAGE':
      log("Updating settings...");
      newState.img = action.img;

      log("Patching the userData image in sessionStorage");
      userData = JSON.parse(sessionStorage.getItem("userData"));
      userData.profile_picture = newState.img;
      sessionStorage.setItem("userData", JSON.stringify(userData));
    break;

    case 'UPDATE_SETTINGS':
      log("Updating settings...");
      newState = _.extend({}, newState, adjustData(action.settings));
      console.log("SettingsReducer: New state:", newState);

      log("Patching the userData info in sessionStorage");
      updateSavedUserData(newState);
    break;

    case "UPDATE_SITE_CONFIG":
      console.log("SettingsReducer: Updating site config.");
      var index = _.findIndex(newState.projectList, (site) => {
        return Number(site.siteid) === Number(action.siteId)
      });
      console.log(index, action);
      if (index > -1) {
        var siteData = JSON.parse(JSON.stringify(newState.projectList[index]));

        if (action.changes.contractedCapacity) {
          siteData.contract_capacity = action.changes.contractedCapacity;
        }
        if (action.changes.contCapAlias) {
          siteData.contCapAlias = action.changes.contCapAlias;
        }
        if (action.changes.billingMonths) {
          siteData.billing_months = action.changes.billingMonths;
        }
        if (action.changes.billStart) {
          siteData.bill_start = action.changes.billStart;
        }
        if (action.changes.billEnd) {
          siteData.bill_end = action.changes.billEnd;
        }
        if (action.changes.weatherLoc) {
          siteData.weather_loc = action.changes.weatherLoc;
        }
        if (action.changes.lat) {
          siteData.lat = action.changes.lat;
        }
        if (action.changes.lat) {
          siteData.lat = action.changes.lat;
        }

        newState.projectList[index] = siteData;
      }
      console.log("SettingsReducer: Updated sites:", newState.projectList);
      console.log("SettingsReducer: Patching the userData info in sessionStorage");
      updateSavedUserData(newState);

    break;

    case "UPDATE_STATUS":
      newState.updateStatus = action.status;
    break;

    case "UPDATE_USER_LIST":
      log("Userlist updated. Setting refresh to false");
      newState.userList = action.userList;
      newState.user.needRefresh = false;
    break;

    default:
      return state
  }

  Object.keys(newState).forEach((key) => {
    if (newState[key] === undefined) {
      delete newState[key];
    }
  });

  return newState;
}

export default settings