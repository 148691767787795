import LogPrinter from "../LogPrinter";
const log = LogPrinter("subscriptionsReducer");

const initialState = {
  analytics: false,
  solar: false,
  wesm: false,
  kwh: true,
  comparison: false,
  billingReport: false,
  fpsc: false
}


const subscriptions = (state = initialState, action) => {
  if (action.category !== "subscriptions")
    return state;

  var newState = JSON.parse(JSON.stringify(state));
  log("Request:", action.type, action);

  switch (action.type) {
    case 'SET_STATUS':
      action.subscriptions.forEach((subs) => {
        switch (subs.name) {
          case "Analytics":
            // newState.analytics = subs.status === "on";
            newState.analytics = true;
          break;
          case "Photovoltaic":
            // newState.solar = subs.status === "on";
            newState.solar = true;
          break;
          case "WESM":
            // newState.wesm = subs.status === "on";
            newState.wesm = false;
          break;
          case "KWH":
            newState.kwh = true;//subs.status === "on";
          break;
          case "BillingReport":
            newState.billingReport = false;//subs.status === "on";
          break;
          case "Comparison":
            newState.comparison = subs.status === "on";
          break;
          case "FPSC":
            newState.fpsc = subs.status === "on";
          break;
          default: break;
        }
      });
      return newState;
    default:
      return state
  }
}

export default subscriptions