import _ from "underscore";
import LogPrinter from "../LogPrinter";
const log = LogPrinter("weatherReducer");


const initialState = {
  retrievedData: undefined,
  timestamp: undefined,
  status: "noData",
  needRefresh: true
}

const weather = (state = initialState, action) => {
  if (action.category !== "weather") 
    return state;

  var newState = _.extend({}, state);
  log("Request:", JSON.stringify(action).substr(0, 100));

  switch (action.type) {
    case "REQUEST_WEATHER": 
      newState.status = "isFetching";
    break;
    case 'UPDATE_WEATHER':
      log("Filling up weather data", action.isSuccess);
      newState.status = action.isSuccess ? "updated" : "noData";
      newState.timestamp = Date.now();
      if (action.weather) {
        newState.retrievedData = action.weather;
      }
      newState.needRefresh = false;
    break;
    case "REFRESH_DATA":
      newState.needRefresh = true;
    break;
    default:
      return state
  }

  var diffs = _.omit(newState, function(v,k) { return state[k] === v; });
  log("Result:", JSON.stringify(diffs).substr(0, 100));

  return newState;
}

export default weather