import _ from "underscore";
import Moment from "moment";
import { extendMoment } from 'moment-range';
import * as dateUtils from "../utils/dateUtils";
import LogPrinter from "../LogPrinter";
const log = LogPrinter("systemsReducer");
const moment = extendMoment(Moment);

const MINUTE = 60 * 1000;

const initStatistics = {
    consumption: 0,
    solar: 0
  };

const initialState = {
  isInterpolated: true,
  startingDate: new Date(),
  endingDate: new Date(),
  time: "billing",
  statistics: initStatistics,
  interpolatedStatistics: initStatistics,
  preloadedData: {
    results: [],
    status: "noData",
    timestamp: undefined,
    needRefresh: true
  },
  savedMonths: [],
  isZoomed: false,
  zoomedMin: null,
  zoomedMax: null,
  status: "",
  graphMode: "line",
  barTimePeriod: "day",
  barReqStatus: "noData",
  barData: {
    results: [],
    interpolated: []
  },
  settingsChanged: false
}

function normalizeData(data,  minutes, isInterpolated) {
  var fixedData = _.chain(data)
    .map((dataPoint) => {
      var displayedValue = Number(dataPoint[1]).toFixed(2);
      if (isInterpolated && dataPoint[2]) {
        displayedValue = Number(dataPoint[2]).toFixed(2);
      }

      return [+dataPoint[0], Number(displayedValue)];
    })
    .filter((dataPoint) => {
      return dataPoint[1] < 100000;
    })
    .sortBy(function(data) { return data[0]; })
    .reduce((prev, curr, i) => {
      if (i > 0) {
        var lastIndex = prev.length - 1;
        var minsDiff = dateUtils.minutesApart(prev[lastIndex][0], curr[0]);
        if (minsDiff === minutes * 2) {
          prev.push([prev[lastIndex][0] + (minutes * MINUTE), null]);
        } else if (minsDiff > minutes * 2) {
          prev.push([prev[lastIndex][0] + (minutes * MINUTE), null]);
          prev.push([curr[0] - (minutes * MINUTE), null]);
        }
      }

      if (curr[1] < 0) {
        prev.push([curr[0], 0]);
      } else {
        prev.push(curr);
      }

      return prev;
    }, [])
    .value();

  return fixedData;
}

function fixMetersData(dataSet, currDataSet, isAppendData, isInterpolated) {
  var pointsArray = [];
  var meterIds = [];

  Object.keys(dataSet).forEach((meterId, index) => {
    log("Processing meter data:", meterId, dataSet[meterId].length);
    pointsArray.push(normalizeData(dataSet[meterId], 5, isInterpolated));
    meterIds.push(meterId);
  });

  // if (isAppendData) {
    log("Appending data", pointsArray, meterIds);

    _.each(currDataSet, (set, i) => {
      log("Pushing data to:", set.meterId, "with size:", set.data.length);

      var index = _.indexOf(meterIds, set.meterId);

      if (index === -1) {
        log("Nothing to update...")
      } else {
        var combinedDataPoints = _.chain(set.data)
          .union(pointsArray[index])
          .sortBy(function(data) { return data[0]; })
          .uniq(true, function(data) { return data[0]; })
          .value();

        pointsArray[index] = normalizeData(combinedDataPoints, 10, isInterpolated);

        log("Data updated. New size:", pointsArray[index].length);
      }
    });
  // }

  return {
    pointsArray: !isInterpolated ? pointsArray : undefined,
    interpolatedPoints: isInterpolated ? pointsArray : undefined,
    meterIds: meterIds
  }
}

const systems = (state = initialState, action) => {
  if (action.category !== "systems")
    return state;

  var newState = JSON.parse(JSON.stringify(state));
  log("Request:", action.type, action);

  switch (action.type) {
    case "CHANGE_PERIOD":
      return { ...state, time: action.time }
    case "SET_START_END":
      return { ...state,
        startingDate: action.start.getTime(),
        endingDate: action.end.getTime()
      }
    case "REQUEST_PRELOAD":
      if (!action.appendData) {
        newState.preloadedData.results = null;
        newState.preloadedData = null;
        newState.preloadedData = initialState.preloadedData;
      }
      var range = moment.range(action.start.getTime(), action.end.getTime());

      const savedMonths = Array.from(range.by('month')).map(months => months.format('MM-YYYY'));

      newState.savedMonths = _.chain(state.savedMonths)
        .union(savedMonths)
        .value();
      newState.preloadedData.status = "isFetching";
    break;
    case "UPDATE_PRELOAD":
      if (action.isSuccess) {

        const dataSet = _.omit(action.data, "status", "statistics", "results", "error", "wesmData");

        var results = fixMetersData(dataSet, state.preloadedData.results, action.appendData, false);

        var interpolated = fixMetersData(dataSet, state.preloadedData.interpolated, action.appendData, true);

        newState.preloadedData = {
          results: _.map(results.pointsArray, (points, i) => {
            return {
              data: points,
              meterId: results.meterIds[i]
            };
          }),
          interpolated: _.map(interpolated.interpolatedPoints, (points, i) => {
            return {
              data: points,
              meterId: interpolated.meterIds[i]
            };
          }),
          timestamp: action.timestamp,
          status: "updated",
          needRefresh: false
        };

        log("Saving new data:", newState.preloadedData.results.length, newState.preloadedData.interpolated.length);
      } else {
        newState.preloadedData.needRefresh = false;
        newState.preloadedData.timestamp = action.timestamp;
        newState.preloadedData.status = "failed";
      }
    break;
    case "UPDATE_PRELOAD_STATUS":
      newState.preloadedData.status = action.isSuccess ? "updated" : "failed";
    break;
    case "INIT_STATS":
      newState.statistics = initStatistics;
      newState.interpolatedStatistics = initStatistics;
    break;
    case "SET_STATISTICS":
      newState.interpolatedStatistics["consumption"] = action.statistics.interpolated_consumption;
      newState.interpolatedStatistics["solar"] = action.statistics.interpolated_solar;
      newState.statistics["consumption"] = action.statistics.consumption;
      newState.statistics["solar"] = action.statistics.solar;
    break;
    case "SET_ZOOM_STATE":
      newState.isZoomed = action.zoomedMin && action.zoomedMax;
      newState.zoomedMin = action.zoomedMin;
      newState.zoomedMax = action.zoomedMax;
    break;
    case "TOGGLE_INTERPOLATION":
      newState.isInterpolated = !newState.isInterpolated;
      log("Interpolation changed:", newState.isInterpolated);
    break;
    case "REFRESH_DATA":
      newState.settingsChanged = true;
      newState.preloadedData.needRefresh = true;
      newState.preloadedData.results = [];
    break;
    case "ZOOM_OUT":
      newState.isZoomed = false;
    break;
    case "SET_GRAPH_MODE":
      newState.graphMode = action.graphMode;
    break;
    case "SET_BAR_TIME_PERIOD":
      newState.barTimePeriod = action.barTimePeriod;
    break;
    case "SET_BAR_REQ_STATUS":
      newState.barReqStatus = action.barReqStatus;
      log("Bar request status:", action.barReqStatus);
    break;
    case "SET_BAR_DATA":
      const dataSet = _.omit(action.barData, "status", "error");
      try {
        newState.barData = {
          results: [
            {
              meterId: "grid",
              data: _.map(dataSet.data["grid"], (point) => {
                return [Number(point[0]), Number(Number(point[1]).toFixed(2))]
              })
            },
            {
              meterId: "solar",
              data: _.map(dataSet.data["solar"], (point) => {
                return [Number(point[0]), Number(Number(point[1]).toFixed(2))]
              })
            },
          ],
          interpolated: [
            {
              meterId: "grid",
              data: _.map(dataSet.interpolated["grid"], (point) => {
                return [Number(point[0]), Number(Number(point[1]).toFixed(2))]
              })
            },
            {
              meterId: "solar",
              data: _.map(dataSet.interpolated["solar"], (point) => {
                return [Number(point[0]), Number(Number(point[1]).toFixed(2))]
              })
            },
          ]
        }
      } catch (err) {
        log("Error:", err);
      }
      log("Bar data updated...", newState.barData);
    break;
    default:
      return state
  }

  return newState;
}

export default systems