import LogPrinter from "../LogPrinter";
const log = LogPrinter("passwordResetReducer");

const initialState = {
  status: "",
  errorText: "",
}

const passwordReset = (state = initialState, action) => {
  if (action.category !== "passwordReset") 
    return state;

  log("Request:", action.type);

  var newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case 'SET_SUBMIT_STATUS':
      newState.status = action.status;
      newState.errorText = action.errorText;
    break;
    default:
      return state
  }
  return newState;
}

export default passwordReset