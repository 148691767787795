import LogPrinter from "../LogPrinter";
const log = LogPrinter("loginReducer");

const initialState = {
  status: "loggedOut",
  errorText: "",
}

const login = (state = initialState, action) => {
  if (action.category !== "login")
    return state;

  log("Request:", action.type);

  var newState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case 'SET_LOGIN_STATUS':
      log("Setting login status:", action.status);
      newState.status = action.status;

      if (action.status === "success") {
        newState.errorText = "";
      }
    break;
    case "SET_LOGIN_ERROR":
      log("Setting login error:", action.errorText);
      newState.errorText = action.errorText;
    break;
    default:
      return state
  }
  return newState;
}

export default login